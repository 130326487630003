<template>
  <b-list-group-item
    class="d-flex align-items-center border-0"
    v-b-modal.modal-add-staff
  >

    <b-modal
      v-model="show"
      id="modal-add-staff"
      v-bind:title="displayText('partner.select_staff', '選擇員工')"
      body-class="modal-height"
      centered
    >
      <b-input-group>
        <b-form-input
          v-bind:placeholder="displayText('partner.select_staff_placeholder', '請輸入姓名或員工編號')"
          v-model="staffKeyword"
          @keyup.enter="fetchStaffs"
        ></b-form-input>
        <b-input-group-append>
          <b-button @click="fetchStaffs"><i class="fa fa-search"></i></b-button>
        </b-input-group-append>
      </b-input-group>
      <div class="text-center mt-3 mb-3 loading-spinner" v-if="loading">
        <b-spinner variant="secondary" small />
      </div>
      <div class="scrolling" v-if="staffs.length > 0 && !loading">
        <div v-for="staff in staffs" v-bind:key="staff.id">
          <div class="d-flex align-items-center py-2 staff-info" @click="handleSelected(staff)">
            <b-avatar
              class="mr-2"
              :src="staff.avatar_url"
            ></b-avatar>
            <div
              class="
                mr-auto
                font-weight-bold
                text-nowrap text-truncate
              "
            >
              {{ staff.name }}<br>
              <span class="staff-list-info">
                {{ displayStaffInfo(staff) }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="selectedStaff"
        class="d-flex align-items-center py-3"
      >
        <b-avatar
          class="mr-2"
          :src="selectedStaff.avatar_url"
        ></b-avatar>
        <div
          class="
            mr-auto
            font-weight-bold
            text-nowrap text-truncate
            flex-fill
          "
        >
          {{ selectedStaff.name }}<br>
          <span class="staff-list-info">
            {{ displayStaffInfo(selectedStaff) }}
          </span>
        </div>
        <b-button
          size="xs"
          pill
          variant="danger"
          class="p-1 mr-2"
          @click="cancelSelected"
        ><i class="fa fa-times m-0"></i
        ></b-button>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <div class="float-right">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 arrow-point"
              @click="changePage(-1)"
            >
              <path
                d="M17.1816 20.3398C17.1816 20.5352 17.2598 20.7109 17.4062 20.8574L24.7598 27.9375C24.8965 28.0742 25.0723 28.1523 25.2773 28.1523C25.6777 28.1523 25.9902 27.8398 25.9902 27.4297C25.9902 27.2246 25.9121 27.0586 25.7852 26.9219L18.9395 20.3398L25.7852 13.748C25.9121 13.6113 25.9902 13.4355 25.9902 13.2402C25.9902 12.8301 25.6777 12.5273 25.2773 12.5273C25.0723 12.5273 24.8965 12.5957 24.7598 12.7324L17.4062 19.8125C17.2598 19.959 17.1816 20.1348 17.1816 20.3398Z"
                fill="black"
              />
            </svg>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-5 arrow-point"
              @click="changePage(1)"
            >
              <path
                d="M25.3438 20.3398C25.3438 20.1348 25.2656 19.959 25.1191 19.8125L17.7656 12.7324C17.6289 12.5957 17.4531 12.5273 17.248 12.5273C16.8477 12.5273 16.5352 12.8301 16.5352 13.2402C16.5352 13.4355 16.6133 13.6113 16.7402 13.748L23.5859 20.3398L16.7402 26.9219C16.6133 27.0586 16.5352 27.2246 16.5352 27.4297C16.5352 27.8398 16.8477 28.1523 17.248 28.1523C17.4531 28.1523 17.6289 28.0742 17.7656 27.9375L25.1191 20.8574C25.2656 20.7109 25.3438 20.5352 25.3438 20.3398Z"
                fill="black"
              />
            </svg>
            <b-button
              variant="danger"
              size="sm"
              class="mr-2 ml-5"
              @click="closeModal"
            >
              取消
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              @click="submitAddMember"
            >
              確定
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </b-list-group-item>
</template>

<script>
import staff from "@/apis/staff";
import module from "@/apis/module";
import _ from 'lodash'
import { mapState } from 'vuex';
import * as consts from '@/consts'
import PermissionChecker from "@/utils/PermissionChecker";
import { displayText } from '@/utils/dashboardUtils'

const PER_PAGE_AMOUNT = 10;

export default {
  props: {
    showAddStaff: Boolean
  },
  data() {
    return {
      selectedStaff: null,
      staffKeyword: null,
      staffs: [],
      consts,
      page: 0,
      lastPage: 1,
      show: this.showAddStaff,
      loading: false,
      partnerModuleConfig: {},
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapState("general", {
      organization: (state) => state.organization,
      dashboardModule: (state) => state.dashboardModule,
    }),
  },
  watch: {
    showAddStaff: {
      handler(val) {
        this.show = val
      }
    },
    show(val) {
      if (!val) {
        this.$emit("close");
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    displayText,
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async init() {
      const response = await module.getModuleConfig({ module_code: 'partner' });
      this.partnerModuleConfig = response.data;
      await this.fetchStaffs();
    },
    async fetchStaffs() {
      this.loading = true
      let params = {}

      if (this.staffKeyword) params.keyword = this.staffKeyword

      // 如果 module_config 有設定預設撈取的角色，則撈取符合該角色的員工
      const roleFilter = this.partnerModuleConfig?.partner_worker?.partner_worker_staff?.roles_filter ?? false;

      if (roleFilter) {
        params.roles = roleFilter;
      }

      params.is_all = true;

      const { data } = await staff.getStaffs(this.organization, params)
      this.allStaffs = _.sortBy(data.data, "employee_code");
      this.lastPage = Math.ceil(this.allStaffs.length / PER_PAGE_AMOUNT);
      this.loading = false
      this.changePage(1, true)
    },
    changePage(page, init = false) {
      this.loading = true
      if (init) {
        this.page = 1
      } else {
        if (this.lastPage < this.page + 1) {
          this.page -= 1
          this.loading = false
          return
        }
        if (this.page + page == 0) {
          this.loading = false
          return
        }
        this.page += page
      }
      this.staffs = []
      const start = (this.page - 1) * PER_PAGE_AMOUNT;
      const end = start + PER_PAGE_AMOUNT;
      for (let i = start; i < end && i < this.allStaffs.length; i++) {
        const item = this.allStaffs[i];
        this.staffs.push(item);
      }
      this.loading = false
    },
    displayStaffInfo(staff) {
      return ((staff.employee_code) ? staff.employee_code + " " : "") + (staff.email ?? "");
    },
    async submitAddMember() {
      if (this.selectedStaff) {
        this.$emit("selected", this.selectedStaff);
        this.show = false
      }
    },
    handleSelected(e) {
      this.selectedStaff = e;
      this.staffKeyword = null;
      this.staffs = []
    },
    cancelSelected() {
      this.selectedStaff = null
      this.staffs = this.allStaffs
    },
    closeModal() {
      this.show = false
      this.selectedStaff=null
    }
  }
}
</script>

<style lang="scss">
.modal-height {
  height: 60vh;
}

.staff-list-info {
  font-size: 0.75rem;
  color: gray;
  font-weight: 400;
}

.scrolling {
  overflow-y: scroll;
  height: 40vh;
}

.staff-info:hover {
  cursor: pointer;
  background-color: gray;
  color: white;
  width: 100%;

  .staff-list-info {
    color: white;
  }
}

.arrow-point:hover {
  cursor: pointer;
}
</style>
