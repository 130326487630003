<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item aria-current="location" active :text="displayText('partner.partner_area', '合作夥伴專區')">
            </b-breadcrumb-item>
            <b-breadcrumb-item aria-current="location" active v-if="isFromParnerPage">
              {{ displayText("partner.partner_management", "合作夥伴管理") }}
            </b-breadcrumb-item>
            <b-breadcrumb-item @click="goBackOrList" v-if="isVerifyPage"
              >{{ displayText("partner.partner_worker_verify_page", "夥伴員工審核") }}
            </b-breadcrumb-item>
            <b-breadcrumb-item @click="goBackOrList" v-else
              >{{ displayText("partner.partner_worker_management", "夥伴員工管理") }}
            </b-breadcrumb-item>

            <b-breadcrumb-item active
              >{{
                isReadOnly
                  ? displayText("partner.worker_view", "查看夥伴員工")
                  : isEditing
                  ? displayText("partner.worker_edit", "編輯夥伴員工")
                  : displayText("partner.worker_add", "新增夥伴員工")
              }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{
                  isReadOnly
                    ? displayText("partner.worker_view", "查看夥伴員工")
                    : isEditing
                    ? displayText("partner.worker_edit", "編輯夥伴員工")
                    : displayText("partner.worker_add", "新增夥伴員工")
                }}
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="displayText('partner.avatar', '個人頭像')"
                  v-if="hiddenField('avatar')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="partnerWorker.avatar_url"
                    :readonly="isReadOnly"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="displayText('partner.worker_code', '員工編號')"
                  v-if="hiddenField('employee_code')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="partnerWorker.employee_code"
                    :readonly="isReadOnly"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="
                    '*' + displayText('partner.worker_name', '員工姓名')
                  "
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="v$.partnerWorker.name.$error ? false : null"
                    v-model="partnerWorker.name"
                    :readonly="isReadOnly"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!v$.partnerWorker.name.$error"
                  >
                    {{ displayText("partner.is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="displayText('partner.email', 'Email')"
                  v-if="hiddenField('email')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="partnerWorker.email"
                    :readonly="isReadOnly"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="displayText('partner.mobile', '手機')"
                  v-if="hiddenField('mobile_phone')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="partnerWorker.mobile_phone"
                    :readonly="isReadOnly"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="
                    (requiredStaff() ? '*' : '') + displayText('partner.worker_sales', '業務員')
                  "
                >
                  <div class="d-flex">
                    <b-form-text
                      v-if="partnerWorker.staff"
                      class="mb-2 mr-sm-2 mb-sm-0"
                    >
                      {{ partnerWorker.staff.name }}
                    </b-form-text>
                    <b-button
                      v-if="!isReadOnly"
                      class="mb-xl-0"
                      variant="primary"
                      @click="showPartnerWorkerAddStaff = true"
                      ><i class="fa fa-plus"></i
                      >{{ displayText("partner.select_sales", "選擇業務員") }}
                    </b-button>
                  </div>
                  <b-form-invalid-feedback
                    :state="!v$.partnerWorker.staff_id.$error"
                  >
                    {{ displayText("partner.is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="'*' + displayText('partner.title', '合作夥伴')"
                >
                  <AppSingleSelect
                    v-model="selectedPartner"
                    :options="partners"
                    @input="setPartnerId"
                    :disabled="isPartnerIdDisabled"
                  />
                  <b-form-invalid-feedback
                    :state="!v$.partnerWorker.partner_id.$error"
                  >
                    {{ displayText("partner.is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="
                    displayText('partner.worker_job_specialty_name', '服務科室')
                  "
                  v-if="hiddenField('job_specialty')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="partnerWorker.job_specialty"
                    :readonly="isReadOnly"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="
                    displayText('partner.worker_job_type', '職位類型')
                  "
                  v-if="hiddenField('job_type')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="partnerWorker.job_type"
                    :readonly="isReadOnly"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="displayText('partner.worker_job_title', '職稱')"
                  v-if="hiddenField('job_title')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="partnerWorker.job_title"
                    :readonly="isReadOnly"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="
                    displayText('partner.worker_priority', '重點客戶')
                  "
                  v-if="hiddenField('priority')"
                >
                  <b-form-select
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :options="priorityOptions"
                    v-model="partnerWorker.priority"
                    :readonly="isReadOnly"
                    :disabled="isReadOnly"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="
                    displayText('partner.worker_category', '客戶分類')
                  "
                  v-if="hiddenField('category')"
                >
                  <b-form-select
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :options="categoryOptions"
                    v-model="partnerWorker.category"
                    :readonly="isReadOnly"
                    :disabled="isReadOnly"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="displayText('partner.line_uid', 'LINE UID')"
                  v-if="hiddenField('line_uid')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="partnerWorker.line_id"
                    :readonly="isReadOnly"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="
                    displayText('partner.worker_dept_name', '部門名稱')
                  "
                  v-if="hiddenField('dept_name')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="partnerWorker.dept_name"
                    :readonly="isReadOnly"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="displayText('partner.created_at', '建立時間')"
                  v-if="hiddenField('created_at') && (isReadOnly || isEditing)"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="partnerWorker.created_at"
                    readonly
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="displayText('partner.enabled_at', '接觸時間')"
                  v-if="hiddenField('enabled_at') && (isReadOnly || isEditing)"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="partnerWorker.enabled_at"
                    readonly
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="displayText('partner.status_at', '驗證時間')"
                  v-if="hiddenField('status_at') && (isReadOnly || isEditing)"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="partnerWorker.status_at"
                    readonly
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="
                    displayText('partner.worker_data_source', '資料來源')
                  "
                  v-if="hiddenField('data_source')"
                >
                  <b-form-select
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :options="dataSourceOptions"
                    v-model="partnerWorker.data_source"
                    :readonly="isReadOnly"
                    :disabled="isReadOnly"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="displayText('partner.is_enabled', '是否啟用')"
                  v-if="hiddenField('is_enabled')"
                >
                  <b-form-checkbox
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="partnerWorker.is_enabled"
                    :disabled="isReadOnly"
                  ></b-form-checkbox>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="6"
                  v-bind:label="displayText('partner.status', '狀態')"
                  v-if="hiddenField('status')"
                >
                  <b-form-select
                    v-model="partnerWorker.status"
                    :disabled="isReadOnly"
                    :options="statusOptions"
                    class="mr-3"
                  ></b-form-select>
                </b-form-group>

              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                class="mr-3"
                @click="goBackOrList"
                variant="outline-danger"
                >返回
              </b-button>
              <b-button
                v-if="!isReadOnly"
                @click="handleSubmit"
                variant="success"
                >儲存</b-button
              >
            </div>
          </b-card>
          <PartnerWorkerAddStaff
            v-if="!isReadOnly"
            :show-add-staff="showPartnerWorkerAddStaff"
            @selected="handleStaffSelected"
            @close="showPartnerWorkerAddStaff = false"
          ></PartnerWorkerAddStaff>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import partnerWorkerApi from "@/apis/partner-worker";
import { zh } from "vuejs-datepicker/dist/locale";
import PartnerWorkerAddStaff from "@/pages/Dashboard/PartnerWorker/PartnerWorkerAddStaff.vue";
import { displayText } from "@/utils/dashboardUtils";
import AppSingleSelect from "@/components/AppSingleSelect.vue";

export default {
  components: { PartnerWorkerAddStaff, AppSingleSelect },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      partnerWorker: {
        org_id: {},
        staff_id: this.requiredStaff() ? { required } : {},
        partner_id: { required },
        name: { required },
        mobile_phone: {},
        email: {},
        line_id: {},
        employee_code: {},
        dept_name: {},
        job_type: {},
        job_title: {},
        job_specialty: {},
        avatar_url: {},
        is_enabled: {},
        status: {},
        category: {},
        data_source: {},
        priority: {},
      },
    };
  },

  data() {
    return {
      zh,
      showPartnerWorkerAddStaff: false,
      showLoading: false,
      isEditing: true,
      validationErrors: null,
      categoryOptions: [],
      priorityOptions: [],
      dataSourceOptions: [],
      statusOptions: [
        {
          text: displayText("partner.status_line_init", "前台新增人員"),
          value: "line_init",
        },
        {
          text: displayText("partner.status_dashboard_init", "後台新增人員"),
          value: "dashboard_init",
        },
        {
          text: displayText("partner.status_approved", "審核通過"),
          value: "approved",
        },
        {
          text: displayText("partner.status_pending", "待審核"),
          value: "pending",
        },
        {
          text: displayText("partner.status_rejected", "審核失敗"),
          value: "rejected",
        },
      ],
      partners: [],
      selectedPartner: {},
      partnerWorker: {
        org_id: null,
        partner_id: null,
        staff_id: null,
        name: null,
        mobile_phone: null,
        email: null,
        line_id: null,
        employee_code: null,
        dept_name: null,
        job_type: null,
        job_title: null,
        job_specialty: null,
        avatar_url: null,
        is_enabled: true,
        status: "dashboard_init",
        staff: {
          idhidden: null,
          name: null,
        },
        data_source: null,
      },
      showLoadingUpload: false,
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      dashboardModule: (state) => state.dashboardModule,
    }),

    ...mapGetters("route", ["routeQuery", "from"]),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),

    isReadOnly() {
      return ["PartnerWorkerView", "PartnerWorkerVerifyingView", "PartnerWorkerFormByPartner"].includes(this.$route.name);
    },

    isPartnerIdDisabled() {
      return this.isReadOnly || this.$route.query.disablePartnerSelect;
    },

    isFromParnerPage() {
      return this.$route.params.partnerId;
    },

    isVerifyPage() {
      return ["PartnerWorkerVerifyingEdit","PartnerWorkerVerifyingView"].includes(this.$route.name);
    },
  },
  mounted() {
    this.init();
    if (!this.$route.params.partnerWorkerId) {
      this.isEditing = false;
    } else {
      this.fetchPartnerWorker();
      this.isEditing = true;
    }
  },
  methods: {
    displayText,
    requiredStaff() {
      const defaultStaffRequired = this.getModuleConfig("partner","partner_worker.partner_worker_staff")?.required_bind_staff
      const staffRequiredConfig = this.getModuleConfig("partner","partner_worker.partner_worker_modify")
      const statusConfig = staffRequiredConfig.find((config) => config.worker_status === this.partnerWorker.status)
      let staffBindRequired = {}
      if (statusConfig && 'required_bind_staff' in statusConfig) {
        staffBindRequired = statusConfig.required_bind_staff ? true : false;
      } else if (defaultStaffRequired) {
        staffBindRequired = defaultStaffRequired ? true : false ;
      }
      return staffBindRequired
    },
    hiddenField(fieldName) {
      if (this.getModuleConfig("partner", "partner_worker.hidden_fields")) {
        return !this.getModuleConfig(
          "partner",
          "partner_worker.hidden_fields"
        ).includes(fieldName);
      } else {
        return true;
      }
    },
    setOptions() {
      if (
        this.getModuleConfig(
          "partner",
          "partner_worker.partner_worker_category"
        )
      ) {
        this.categoryOptions = this.getModuleConfig(
          "partner",
          "partner_worker.partner_worker_category"
        )
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return {
              text: c.text,
              value: c.key,
            };
          });
      }
      if (
        this.getModuleConfig(
          "partner",
          "partner_worker.partner_worker_priority"
        )
      ) {
        this.priorityOptions = this.getModuleConfig(
          "partner",
          "partner_worker.partner_worker_priority"
        )
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return {
              text: c.text,
              value: c.key,
            };
          });
      }
      if (
        this.getModuleConfig(
          "partner",
          "partner_worker.partner_worker_data_source"
        )
      ) {
        this.dataSourceOptions = this.getModuleConfig(
          "partner",
          "partner_worker.partner_worker_data_source"
        )
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return {
              text: c.text,
              value: c.key,
            };
          });
        if (this.partnerWorker.data_source === null) {
          this.partnerWorker.data_source = this.dataSourceOptions[0].value;
        }
      }
    },
    async init() {
      await this.fetchPartner();
      this.setOptions();
      if (this.$route.params.partnerId) {
        this.partnerWorker.partner_id = this.$route.params.partnerId;
        this.selectedPartner = this.partners.find(
          (p) => p.value == this.partnerWorker.partner_id
        );
      }
    },
    async fetchPartnerWorker() {
      this.showLoading = true;
      const response = await partnerWorkerApi.getPartnerWorker(
        this.organization,
        this.$route.params.partnerWorkerId
      );
      this.partnerWorker = response.data.data;
      let selectedPatner = this.partners.find((p) => p.value == this.partnerWorker.partner_id)
      if (!selectedPatner) {
        selectedPatner = {
          text: (this.partnerWorker.partner_code ?? '') + " " + this.partnerWorker.partner_name,
          value: this.partnerWorker.partner_id,
        }
        this.partners.push(selectedPatner)
      }
      this.selectedPartner = selectedPatner;
      this.showLoading = false;
    },
    async fetchPartner() {
      if (this.partners.length > 0) return;

      try {
        const { data } = await partnerWorkerApi.getPartnerList(
          this.organization
        );
        data.data.forEach((partner) => {
          this.partners.push({
            text: partner.partner_code + " " + partner.name,
            value: partner.id,
          });
        });
      } catch (error) {
        console.error(error);
        this.$swal(
          "錯誤",
          displayText("partner.api_failed", "讀取資料錯誤"),
          "error"
        );
      }
    },
    handleStaffSelected(staff) {
      this.partnerWorker.staff_id = staff.id;
      this.partnerWorker.staff = staff;
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;

      try {
        if (this.isEditing) {
          let response = await partnerWorkerApi.updatePartnerWorker(
            this.organization,
            this.partnerWorker
          );

          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "更新成功",
              type: "success",
            });
          }
        } else {
          let response = await partnerWorkerApi.createPartnerWorker(
            this.organization,
            this.partnerWorker
          );

          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "新增成功",
              type: "success",
            });
          }
        }
        this.goBackOrList();
      } catch (error) {
        console.error(error);
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            title: this.isEditing ? "更新失敗" : "新增失敗",
            type: "error",
          });
        }
      }
    },
    setPartnerId() {
      this.partnerWorker.partner_id = this.selectedPartner?.value;
    },
    goBackOrList() {
      if (this.$route.params.partnerId) {
        this.$router.push({
          name: "PartnerWorkerListByPartner",
          params: {
            partnerId: this.$route.params.partnerId
          },
          query: this.routeQuery
        });
      } else if (this.isVerifyPage) {
        this.$router.push({ name: "PartnerWorkerListVerifying", query: this.routeQuery });
      }
      else {
        this.$router.push({ name: "PartnerWorkerList", query: this.routeQuery });
      }
    },
  },
};
</script>
<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}
</style>
